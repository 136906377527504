<template>
  <div class="container">
    <h1>Privacy Policy</h1>
    <p>
      At Lighter Life LTD, we are committed to protecting your privacy. Lighter Life LTD, the provider of the Litely app
      (“Litely,” “we,” “us,” or “our”), has prepared this Privacy Policy to explain what Personal Data (defined below)
      we collect, how we use and share that data, and your choices concerning our data practices. Our mobile application
      (the “App”) hosts an online fasting community where users can access resources, set goals, and track long-term
      progress (such services, including our web-based platform found at <a
        href="https://www.litely.life/">https://www.litely.life/</a> (the “Site”) and the App, are referred to
      collectively in this Privacy Policy as the “Service”).
    </p>
    <p>
      This Privacy Policy explains what Personal Data (defined below) we collect, how we use and share that data, and
      your choices concerning our data practices. This Privacy Policy is incorporated into and forms part of our Terms
      of Service. AGREEMENT: Before using the Service or submitting any Personal Data to Litely, please review this
      Privacy Policy carefully and contact us if you have any questions. By using the Service, you expressly consent to
      Lighter Life LTD's collection, use, maintenance and disclosure of your Personal Data in accordance with the
      practices described in this Privacy Policy. If you do not agree to this Privacy Policy, please do not access the
      Site/ the App or otherwise use the Service.
    </p>

    <h2>
      INFORMATION WE COLLECT ABOUT YOU
    </h2>
    <p>
      We collect information that alone or in combination with other information in our possession could be used to
      identify you (“Personal Data”) as follows:

    </p>
    <p>

      Personal Data You Provide: We collect the following categories of Personal Data from you when you create an
      account, use the Service, or communicate with us:
    </p>
    <p>

      Identification Data: We collect your name, email address, and unique identifiers assigned by third party platforms
      (such as analytics or communication tools). If you sign up using credentials from a third party account such as
      Apple, we may collect your name and email address from the applicable third party.
    </p>
    <p>

      Fasting Data: we collect information relating to your fasts, such as the length of your fasts and the information
      contained in the notes you submit relating to your fasts
    </p>
    <p>

      Health Data: We may collect your resting heart rate, weight, and sleep data, either directly from you or from
      Apple Health or Google Fit, if you choose to provide such information. We may also collect certain medical
      condition information, including data relating to Type 1 Diabetes, Type 2 Diabetes, PCOS, Non-Alcoholic Fatty
      Liver Disease, Obesity (BMI), and Eating Disorder, if you choose to provide it to us. Notwithstanding anything
      else in this Privacy Policy, (a) we use Health Data only to provide the Service and not for advertising and (b) we
      will not disclose Health Data for advertising, marketing, or use-based data mining not needed to provide the
      Service.
    </p>
    <p>

      Communication Data: We may collect information when you contact us with questions or concerns and when you
      voluntarily respond to questionnaires, surveys or requests for market research seeking your opinion and feedback.
      Providing this information is optional to you.
    </p>
    <p>

      Demographic Data: We collect your age and gender if you choose to provide them during sign-up.
    </p>
    <p>

      Social Media Data: We have pages on social media sites like Instagram, Facebook, and Twitter (“Social Media
      Pages”). When you interact with our Social Media Pages, we will collect Personal Data that you elect to provide to
      us, such as your contact details. In addition, the companies that host our Social Media Pages may provide us with
      aggregate information and analytics regarding the use of our Social Media Pages.
    </p>

    <p>

      Internet Activity Data: When you visit, use, and interact with the Service, we may receive certain information
      about your visit, use, or interactions. For example, we may monitor the number of people that visit the Service,
      peak hours of visits, which page(s) are visited, the domains our visitors come from (e.g., <a
        href="http://google.com"> google.com</a>,<a href="http://yahoo.com"> yahoo.com</a>,
      etc.), and which browsers people use to access the Service (e.g., Chrome, Firefox, Microsoft Internet Explorer,
      etc.), broad geographical information, and navigation pattern. In particular, the following information is created
      and automatically logged in our systems:
    </p>
    <p>

      Log Data: Information that your browser automatically sends whenever you visit the Site. Log Data includes your
      Internet Protocol address, browser type and settings, the date and time of your request, and how you interacted
      with the Site. Such Log Data is also collected when you interact with the App.
    </p>
    <p>

      Cookies Data: Please see the “Cookies” section below to learn more about how we use cookies.
    </p>
    <p>

      Device Data: Includes name of the device, operating system, and browser you are using. Information collected may
      depend on the type of device you use and its settings.
    </p>
    <p>

      Usage Data: We collect information about how you use our Service, such as the types of content that you view or
      engage with, the features you use, the actions you take, and the time, frequency, and duration of your activities.
    </p>
    <p>

      Location Data: We may derive a rough estimate of your location from your IP address. We may also collect your
      location using GPS coordinates if you choose to provide it through the App.
    </p>
    <p>

      Cookies: We use cookies to operate and administer our Site, gather usage data on our Site, and improve your
      experience on it. A “cookie” is a piece of information sent to your browser by a website you visit. Cookies can be
      stored on your computer for different periods of time. Some cookies expire after a certain amount of time, or upon
      logging out (session cookies), others survive after your browser is closed until a defined expiration date set in
      the cookie (as determined by the third party placing it), and help recognize your computer when you open your
      browser and browse the Internet again (persistent cookies).
    </p>
    <p>

      On most web browsers, you will find a “help” section on the toolbar. Please refer to this section for information
      on how to receive a notification when you are receiving a new cookie and how to turn cookies off.
    </p>
    <p>

      Please note that if you limit the ability of websites to set cookies, you may be unable to access certain parts of
      the Site and you may not be able to benefit from the full functionality of the Site.
    </p>
    <p>

      We may deliver a file to you through the Service (known as a “web beacon”) from an ad network. Web beacons allow
      ad networks to provide anonymized, aggregated auditing, research and reporting for us and for advertisers. Web
      beacons also enable ad networks to serve targeted advertisements to you when you visit other websites. Because
      your web browser must request these advertisements and web beacons from the ad network’s servers, these companies
      can view, edit, or set their own cookies, just as if you had requested a web page from their site. You may be able
      to opt-out of web beacon tracking conducted by third parties through our Service by adjusting the Do Not Track
      settings on your browser; please note that we don’t control whether or how these third parties comply with Do Not
      Track requests.
    </p>
    <p>

      Advertising networks may use cookies to collect Personal Data. Most advertising networks offer you a way to opt
      out of targeted advertising. If you would like to find out more information, please visit the Network Advertising
      Initiative’s online resources at <a href="http://www.networkadvertising.org">http://www.networkadvertising.org</a>
      and follow the opt-out instructions there.
    </p>
    <p>

      If you access the Site on your mobile device, you may not be able to control tracking technologies through the
      settings.
    </p>
    <p>

      Analytics: We use Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics
      uses cookies to help us analyze how users use the Site and enhance your experience when you use the Site. For more
      information on how Google uses this data, go to <a href="http://www.google.com/policies/privacy/partners/">
        www.google.com/policies/privacy/partners/</a>.
    </p>
    <p>

      Online Tracking and Do Not Track Signals: We and our third party service providers, including Facebook, may use
      cookies, pixels, or other tracking technologies to collect information about your browsing activities over time
      and across different websites following your use of the Site and use that information to send targeted
      advertisements. Our Site currently does not respond to “Do Not Track” (“DNT”) signals and operates as described in
      this Privacy Policy whether or not a DNT signal is received. If we do respond to DNT signals in the future, we
      will update this Privacy Policy to describe how we do so.
    </p>

    <h2>

      HOW WE USE INFORMATION
    </h2>
    <p>

      We use the information we collect for the following purposes.
    </p>
    <h2>

      PROVIDE AND MAINTAIN THE SERVICE
    </h2>
    <p>

      We use the information we collect to deliver the Service to you and honor our Terms of Service for each Service or
      business contract with you. For example,
    </p>
    <p>

      to administer, operate, maintain and secure our Service;
    </p>
    <p>

      to monitor and analyze trends, usage and activities in connection with our Service;
    </p>
    <p>

      for accounting, recordkeeping, backup and administrative purposes;
    </p>
    <p>

      to customize and improve the content of our communications, websites and social media accounts;
    </p>
    <p>

      to provide customer service and support;
    </p>
    <p>

      to communicate with you, including responding to your comments, questions and requests regarding our Service; and
    </p>
    <p>

      to process and complete transactions, and send you related information, including alerts and notifications about
      your service, purchase confirmations and invoices; and
    </p>
    <p>

      to educate and train our workforce in data protection and customer support.
    </p>
    <p>

      For the Service’ social features, we may use your information to help you find and connect with other users and to
      allow other users to find and connect with you. For example, your account contact information allows other users
      to add you as a friend. When another user has your email or mobile phone number in their contact list or in their
      friend network on a connected service, we may show that user that you are a user of the Service.
    </p>
    <h2>

      IMPROVE, PERSONALIZE, AND DEVELOP THE SERVICE
    </h2>
    <p>

      We use the information we collect to improve and personalize the Service and to develop new ones. For example, we
      use the information to troubleshoot and protect against errors; perform data analysis and testing; conduct
      research and surveys and develop new features and Service.
    </p>
    <h2>

      COMMUNICATE WITH YOU
    </h2>
    <p>

      We use your information when needed to send you Service notifications and respond to you when you contact us. We
      also use your information to promote new features or products that we think you would be interested in. You can
      control marketing communications and most Service notifications by using your notification preferences in account
      settings or via the “Unsubscribe” link in an email.
    </p>
    <h2>

      PROMOTE SAFETY AND SECURITY
    </h2>
    <p>

      We use the information we collect to promote the safety and security of the Service, our users and other parties.
      For example, we may use the information
    </p>
    <p>

      to authenticate users;
    </p>
    <p>

      to facilitate secure payments;
    </p>
    <p>

      to respond to a legal request or claim, conduct audits, and enforce our terms and policies;
    </p>
    <p>

      to investigate and protect against fraud, malicious or unauthorized access, and other illegal activities; and
    </p>
    <p>
      to demonstrate and verify compliance with our internal policies and procedures, and applicable privacy and data
      security laws and regulations, such as HIPAA and GDPR.
    </p>
    <h2>

      USE AND DISCLOSURE OF DE-IDENTIFIED INFORMATION
    </h2>
    <p>
      “De-identified” means that we have removed, or rendered unreadable through complex computational algorithms, your
      personally-identifiable information, such as your name, address, or birthdate. We may use de-identified
      information that we have obtained from our Service for various purposes, including for example:
    </p>
    <p>
      In accordance with regulatory requirements, we may de-identify, store and use your information for internal
      quality control, validation and research and development. This is important for Lighter Life LTD to maintain high
      quality Service. We may use de-identified information as permitted by law.
    </p>
    <p>

      We may contribute de-identified genetic variants that we have observed in the course of providing our Service to
      publicly available databases such as ClinVar. We do this to increase understanding and raise awareness of the
      significance of genetic variants within the medical and scientific communities.
    </p>
    <p>
      We may use or disclose de-identified information for general research and communications purposes. This may
      include analysis of this information to communicate observations and learnings, for example in the case of
      aggregated data. This may also include research collaborations with third parties, such as universities, hospitals
      or other laboratories, in which we utilize de-identified clinical cases, at the individual level or in the
      aggregate, in accordance with our study protocols, and we may present or publish such information. This may also
      include commercial collaborations with private companies for purposes such as to determine the prevalence of
      particular disorders or variants among the patients we have tested, or to determine whether any of the patients we
      have tested might be suitable for potential recruitment for research, clinical trials, or clinical care; however,
      we will not directly contact these patients about these opportunities without their prior written consent.
    </p>
    <p>
      We use cookies and similar technologies for the purposes described above.
    </p>
    <p>
      For personal data subject to the GDPR, we rely on several legal bases to process the data. These include when you
      have given your consent, which you may withdraw at any time using your account settings and/or other tools; when
      the processing is necessary to perform a contract with you, like the Terms of Service; and our legitimate business
      interests, such as in improving, personalizing, and developing the Service, marketing new features or products
      that may be of interest, and promoting safety and security as described above.
    </p>

    <h2>

      HOW WE SHARE YOUR INFORMATION
    </h2>
    <p>
      Litely does not sell your Personal Data. In certain circumstances we may share the categories of Personal Data
      described above without further notice to you, unless required by the law, with the following categories of third
      parties:
    </p>
    <p>
      Vendors and Service Providers: To assist us in meeting business operations needs and to perform certain services
      and functions, we may share Personal Data with vendors and service providers, including providers of cloud
      hosting/computing services, database providers, email delivery and in-app/push messaging services, advertising and
      marketing services, payment processors, content monitoring services, and web, subscription, and app analytics
      services. Pursuant to our instructions, these parties will access, process, or store Personal Data in the course
      of performing their duties to us. We take commercially reasonable steps to ensure our service providers adhere to
      the security standards we apply to your Personal Data.
    </p>
    <p>
      Business Transfers: If we are involved in a merger, acquisition, financing due diligence, reorganization,
      bankruptcy, receivership, sale of all or a portion of our assets, or transition of service to another provider
      (collectively a “Transaction”), your Personal Data and other information may be shared in the diligence process
      with counterparties and others assisting with the Transaction and transferred to a successor or affiliate as part
      of that Transaction along with other assets.
    </p>
    <p>
      Legal Requirements: If required to do so by law or in the good faith belief that such action is necessary to (i)
      comply with a legal obligation, including to meet national security or law enforcement requirements, (ii) protect
      and defend our rights or property, (iii) prevent fraud, (iv) act in urgent circumstances to protect the personal
      safety of users of the Service, or the public, or (v) protect against legal liability.
    </p>
    <p>
      Other Users: Certain user profile information, including your name, location, and any video or image content that
      such user has uploaded to the Service, may be displayed to other users to facilitate user interaction within the
      Service or address your request for our Service. Please remember that any content you upload to your public user
      profile, along with any Personal Data or content that you voluntarily disclose online in a manner other users can
      view (on discussion boards, blogs, in messages and chat areas, etc.) becomes publicly available, and can be
      collected and used by anyone. Your user name may also be displayed to other users if and when you send messages or
      comments or upload images or videos through the Service and other users can contact you through messages and
      comments.
    </p>
    <p>
      Health Apps: Through the Service you can share your Health Data with other health Apps (We will not share Health
      Data with Advertisers (defined below) under any circumstances.
    </p>
    <p>
      Advertisers: We allow advertisers and/or merchant partners (“Advertisers”) to choose the Demographic and Location
      Information of users who will see their advertisements and/or promotional offers and you agree that we may provide
      Demographic and Location Information we have collected from you in non-personally identifiable form to an
      Advertiser, in order for that Advertiser to select the appropriate audience for those advertisements and/or
      offers. For example, we might use the fact you are located in New York City to show you ads or offers for New York
      City businesses, but we will not tell such businesses who you are. Or, we might allow Advertisers to display their
      ads to users with similar usage patterns to yours, but we will not disclose Internet Activity Data to Advertisers
      except in aggregate form, and not in a manner that would identify you personally. Note that if an advertiser asks
      us to show an ad to a certain audience or audience segment and you respond to that ad, the Advertiser may know
      that you clicked on the ad and conclude that you fit the description of the audience the Advertiser was trying to
      reach.
    </p>
    <h2>

      DATA RETENTION
    </h2>
    <p>
      We keep Personal Data for as long as reasonably necessary for the purposes described in this Privacy Policy, while
      we have a business need to do so, or as required by law (e.g. for tax, legal, accounting, or other purposes),
      whichever is longer.
    </p>
    <h2>

      UPDATE YOUR INFORMATION
    </h2>
    <p>
      Please log in to your account or contact us if you need to change or correct your Personal Data.
    </p>
    <h2>

      CALIFORNIA PRIVACY RIGHTS DISCLOSURES
    </h2>
    <p>
      Where provided for by law and subject to any applicable exceptions, California residents may have the following
      rights:
    </p>
    <p>
      To know the categories of Personal Data that Litely has collected about you, the business purpose for collecting
      your Personal Data, and the categories of sources from which the Personal Data was collected;
    </p>
    <p>
      To access the specific pieces of Personal Data that Litely has collected about you;
    </p>
    <p>
      To know whether Litely has disclosed your Personal Data for business purposes, the categories of Personal Data so
      disclosed, and the categories of third parties to whom we have disclosed your Personal Data;
    </p>
    <p>
      To have Litely, under certain circumstances, delete your Personal Data; and
    </p>
    <p>
      To be free from discrimination related to the exercise of these rights.
    </p>
    <p>
      If you would like to exercise any or all of these rights, you may do so by contacting us. Your authorized agent
      may submit requests in the same manner. Once we receive your request, we will verify your identity by sending an
      email to the email address you provide to us.
    </p>
    <p>
      Please contact us if you have questions about your rights or our disclosures under the CCPA, or to request access
      to an alternative format of this Privacy Policy.
    </p>
    <h2>

      OUR POLICIES FOR CHILDREN
    </h2>
    <p>
      Our Service is directed toward adults. If you are under the age of 16, you must obtain the authorization of a
      responsible adult (parent or legal custodian) before using or accessing our Our Service. We will not knowingly
      collect or use any personal information from any children under the age of 16 on our Our Service. If we become
      aware that we have collected any personal information from children under 16, we will promptly remove such
      information from our systems.
    </p>
    <h2>

      OTHER WEBSITES
    </h2>
    <p>
      The Service may contain links to other websites not operated or controlled by Litely, including social media
      services (“Third Party Sites”). In certain situations, Third Party Sites may sell or provide products or services
      to you through or in connection with the Service (either alone or jointly with us). One such service may include
      the ability for you to automatically transmit information between your Service profile and your accounts at Third
      Party Sites. The information that you share with Third Party Sites will be governed by the specific privacy
      policies and terms of service of the Third Party Sites and not by this Privacy Policy. By providing these links we
      do not imply that we endorse or have reviewed these sites. Please contact the Third Party Sites directly for
      information on their privacy practices and policies.
    </p>
    <h2>

      SECURITY
    </h2>
    <p>
      You use the Service at your own risk. We implement commercially reasonable technical, administrative, and
      organizational measures to protect Personal Data both online and offline from loss, misuse, and unauthorized
      access, disclosure, alteration, or destruction. However, no Internet or e-mail transmission is ever fully secure
      or error free. In particular, e-mail sent to or from us may not be secure. Therefore, you should take special care
      in deciding what information you send to us via the Service or e-mail. Please keep this in mind when disclosing
      any Personal Data to Litely via the Internet. In addition, we are not responsible for circumvention of any privacy
      settings or security measures contained on the Service, or third party websites.
    </p>

    <h2>

      INTERNATIONAL USERS
    </h2>
    <p>
      By using our Service, you understand and acknowledge that your Personal Data will be transferred from your
      location to our facilities and servers in the United States.
    </p>
    <h2>

      YOUR CHOICES
    </h2>
    <p>
      In certain circumstances providing Personal Data is optional. However, if you choose not to provide Personal Data
      that is needed to use some features of our Service, you may be unable to use those features. You can also contact
      us to ask us to update or correct your Personal Data at support@mail.litely.life [You may also delete your
      account. Please note that we will need to verify that you have the authority to delete the account and certain
      activity generated prior to deletion may remain stored by us and may be shared with third parties as detailed in
      this Privacy Policy.]
    </p>
    <h2>

      EXCLUSIONS
    </h2>
    <p>
      This Privacy Policy shall not apply to any unsolicited information you provide to us through the Services or
      through any other means. This includes, but is not limited to, any ideas for new products or modifications to
      existing products, and other unsolicited submissions (collectively, “Unsolicited Information”). All Unsolicited
      Information shall be deemed to be non-confidential and we shall be free to reproduce, use, disclose, and
      distribute such Unsolicited Information to others without limitation or attribution.
    </p>
    <h2>

      CHANGES TO THE PRIVACY POLICY
    </h2>
    <p>
      The Service and our business may change from time to time. As a result we may change this Privacy Policy at any
      time. When we do we will post an updated version on this page, unless another type of notice is required by the
      applicable law. By continuing to use our Service or providing us with Personal Data after we have posted an
      updated Privacy Policy, or notified you by other means if applicable, you consent to the revised Privacy Policy
      and practices described in it.
    </p>
    <h2>

      CONTACT US
    </h2>
    <p>
      If you have any questions about our Privacy Policy or information practices, please feel free to contact us at our
      designated request address: support@mail.litely.life.
    </p>



























    <!-- <h1>Privacy Policy</h1>
    <p>Last updated: April 08, 2022</p>
    <p>
      This Privacy Policy describes Our policies and procedures on the
      collection, use and disclosure of Your information when You use the
      Service and tells You about Your privacy rights and how the law protects
      You.
    </p>
    <p>
      We use Your Personal data to provide and improve the Service. By using the
      Service, You agree to the collection and use of information in accordance
      with this Privacy Policy. This Privacy Policy has been created with the
      help of the
      <a href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/" target="_blank">Privacy Policy
        Template</a>.
    </p>
    <h1>Interpretation and Definitions</h1>
    <h2>Interpretation</h2>
    <p>
      The words of which the initial letter is capitalized have meanings defined
      under the following conditions. The following definitions shall have the
      same meaning regardless of whether they appear in singular or in plural.
    </p>
    <h2>Definitions</h2>
    <p>For the purposes of this Privacy Policy:</p>

    <p>
      <strong>Account</strong> means a unique account created for You to access
      our Service or parts of our Service.
    </p>

    <p>
      <strong>Affiliate</strong> means an entity that controls, is controlled by
      or is under common control with a party, where &quot;control&quot; means
      ownership of 50% or more of the shares, equity interest or other
      securities entitled to vote for election of directors or other managing
      authority.
    </p>

    <p>
      <strong>Application</strong> means the software program provided by the
      Company downloaded by You on any electronic device, named Litely
    </p>

    <p>
      <strong>Company</strong> (referred to as either &quot;the Company&quot;,
      &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
      refers to Lighter Life Limited, Room 1902, Yishi commercial building,
      253-261 Hennessy Road, Wanchai, Hong Kong.
    </p>

    <p>
      <strong>Cookies</strong> are small files that are placed on Your computer,
      mobile device or any other device by a website, containing the details of
      Your browsing history on that website among its many uses.
    </p>

    <p><strong>Country</strong> refers to: Hong Kong SAR China</p>

    <p>
      <strong>Device</strong> means any device that can access the Service such
      as a computer, a cellphone or a digital tablet.
    </p>

    <p>
      <strong>Personal Data</strong> is any information that relates to an
      identified or identifiable individual.
    </p>

    <p>
      <strong>Service</strong> refers to the Application or the Website or both.
    </p>

    <p>
      <strong>Service Provider</strong> means any natural or legal person who
      processes the data on behalf of the Company. It refers to third-party
      companies or individuals employed by the Company to facilitate the
      Service, to provide the Service on behalf of the Company, to perform
      services related to the Service or to assist the Company in analyzing how
      the Service is used.
    </p>

    <p>
      <strong>Third-party Social Media Service</strong> refers to any website or
      any social network website through which a User can log in or create an
      account to use the Service.
    </p>

    <p>
      <strong>Usage Data</strong> refers to data collected automatically, either
      generated by the use of the Service or from the Service infrastructure
      itself (for example, the duration of a page visit).
    </p>

    <p>
      <strong>Website</strong> refers to Litely, accessible from
      <a href="https://www.litely.life/" rel="external nofollow noopener" target="_blank">https://www.litely.life</a>
    </p>

    <p>
      <strong>You</strong> means the individual accessing or using the Service,
      or the company, or other legal entity on behalf of which such individual
      is accessing or using the Service, as applicable.
    </p>

    <h1>Collecting and Using Your Personal Data</h1>
    <h2>Types of Data Collected</h2>
    <h3>Personal Data</h3>
    <p>
      While using Our Service, We may ask You to provide Us with certain
      personally identifiable information that can be used to contact or
      identify You. Personally identifiable information may include, but is not
      limited to:
    </p>

    <p>Email address</p>

    <p>First name and last name</p>

    <p>Phone number</p>

    <p>Address, State, Province, ZIP/Postal code, City</p>

    <p>Usage Data</p>

    <h3>Usage Data</h3>
    <p>Usage Data is collected automatically when using the Service.</p>
    <p>
      Usage Data may include information such as Your Device's Internet Protocol
      address (e.g. IP address), browser type, browser version, the pages of our
      Service that You visit, the time and date of Your visit, the time spent on
      those pages, unique device identifiers and other diagnostic data.
    </p>
    <p>
      When You access the Service by or through a mobile device, We may collect
      certain information automatically, including, but not limited to, the type
      of mobile device You use, Your mobile device unique ID, the IP address of
      Your mobile device, Your mobile operating system, the type of mobile
      Internet browser You use, unique device identifiers and other diagnostic
      data.
    </p>
    <p>
      We may also collect information that Your browser sends whenever You visit
      our Service or when You access the Service by or through a mobile device.
    </p>
    <h3>Information from Third-Party Social Media Services</h3>
    <p>
      The Company allows You to create an account and log in to use the Service
      through the following Third-party Social Media Services:
    </p>

    <p>Google</p>
    <p>Facebook</p>
    <p>Twitter</p>

    <p>
      If You decide to register through or otherwise grant us access to a
      Third-Party Social Media Service, We may collect Personal data that is
      already associated with Your Third-Party Social Media Service's account,
      such as Your name, Your email address, Your activities or Your contact
      list associated with that account.
    </p>
    <p>
      You may also have the option of sharing additional information with the
      Company through Your Third-Party Social Media Service's account. If You
      choose to provide such information and Personal Data, during registration
      or otherwise, You are giving the Company permission to use, share, and
      store it in a manner consistent with this Privacy Policy.
    </p>
    <h3>Information Collected while Using the Application</h3>
    <p>
      While using Our Application, in order to provide features of Our
      Application, We may collect, with Your prior permission:
    </p>

    <p>Information regarding your location</p>
    <p>Information from your Device's phone book (contacts list)</p>

    <p>
      Pictures and other information from your Device's camera and photo library
    </p>

    <p>
      We use this information to provide features of Our Service, to improve and
      customize Our Service. The information may be uploaded to the Company's
      servers and/or a Service Provider's server or it may be simply stored on
      Your device.
    </p>
    <p>
      You can enable or disable access to this information at any time, through
      Your Device settings.
    </p>
    <h3>Tracking Technologies and Cookies</h3>
    <p>
      We use Cookies and similar tracking technologies to track the activity on
      Our Service and store certain information. Tracking technologies used are
      beacons, tags, and scripts to collect and track information and to improve
      and analyze Our Service. The technologies We use may include:
    </p>

    <p>
      <strong>Cookies or Browser Cookies.</strong> A cookie is a small file
      placed on Your Device. You can instruct Your browser to refuse all Cookies
      or to indicate when a Cookie is being sent. However, if You do not accept
      Cookies, You may not be able to use some parts of our Service. Unless you
      have adjusted Your browser setting so that it will refuse Cookies, our
      Service may use Cookies.
    </p>

    <p>
      <strong>Flash Cookies.</strong> Certain features of our Service may use
      local stored objects (or Flash Cookies) to collect and store information
      about Your preferences or Your activity on our Service. Flash Cookies are
      not managed by the same browser settings as those used for Browser
      Cookies. For more information on how You can delete Flash Cookies, please
      read &quot;Where can I change the settings for disabling, or deleting
      local shared objects?&quot; available at
      <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
        rel="external nofollow noopener" target="_blank">
        <p>
          https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html
        </p>
      </a>
    </p>

    <p>
      <strong>Web Beacons.</strong> Certain sections of our Service and our
      emails may contain small electronic files known as web beacons (also
      referred to as clear gifs, pixel tags, and single-pixel gifs) that permit
      the Company, for example, to count users who have visited those pages or
      opened an email and for other related website statistics (for example,
      recording the popularity of a certain section and verifying system and
      server integrity).
    </p>

    <p>
      Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
      Persistent Cookies remain on Your personal computer or mobile device when
      You go offline, while Session Cookies are deleted as soon as You close
      Your web browser. Learn more about cookies:
      <a href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking"
        target="_blank">Use of Cookies by Free Privacy Policy</a>.
    </p>
    <p>
      We use both Session and Persistent Cookies for the purposes set out below:
    </p>

    <p><strong>Necessary / Essential Cookies</strong></p>
    <p>Type: Session Cookies</p>
    <p>Administered by: Us</p>
    <p>
      Purpose: These Cookies are essential to provide You with services
      available through the Website and to enable You to use some of its
      features. They help to authenticate users and prevent fraudulent use of
      user accounts. Without these Cookies, the services that You have asked for
      cannot be provided, and We only use these Cookies to provide You with
      those services.
    </p>

    <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
    <p>Type: Persistent Cookies</p>
    <p>Administered by: Us</p>
    <p>
      Purpose: These Cookies identify if users have accepted the use of cookies
      on the Website.
    </p>

    <p><strong>Functionality Cookies</strong></p>
    <p>Type: Persistent Cookies</p>
    <p>Administered by: Us</p>
    <p>
      Purpose: These Cookies allow us to remember choices You make when You use
      the Website, such as remembering your login details or language
      preference. The purpose of these Cookies is to provide You with a more
      personal experience and to avoid You having to re-enter your preferences
      every time You use the Website.
    </p>

    <p>
      For more information about the cookies we use and your choices regarding
      cookies, please visit our Cookies Policy or the Cookies section of our
      Privacy Policy.
    </p>
    <h2>Use of Your Personal Data</h2>
    <p>The Company may use Personal Data for the following purposes:</p>

    <p>
      <strong>To provide and maintain our Service</strong>, including to monitor
      the usage of our Service.
    </p>

    <p>
      <strong>To manage Your Account:</strong> to manage Your registration as a
      user of the Service. The Personal Data You provide can give You access to
      different functionalities of the Service that are available to You as a
      registered user.
    </p>

    <p>
      <strong>For the performance of a contract:</strong> the development,
      compliance and undertaking of the purchase contract for the products,
      items or services You have purchased or of any other contract with Us
      through the Service.
    </p>

    <p>
      <strong>To contact You:</strong> To contact You by email, telephone calls,
      SMS, or other equivalent forms of electronic communication, such as a
      mobile application's push notifications regarding updates or informative
      communications related to the functionalities, products or contracted
      services, including the security updates, when necessary or reasonable for
      their implementation.
    </p>

    <p>
      <strong>To provide You</strong> with news, special offers and general
      information about other goods, services and events which we offer that are
      similar to those that you have already purchased or enquired about unless
      You have opted not to receive such information.
    </p>

    <p>
      <strong>To manage Your requests:</strong> To attend and manage Your
      requests to Us.
    </p>

    <p>
      <strong>For business transfers:</strong> We may use Your information to
      evaluate or conduct a merger, divestiture, restructuring, reorganization,
      dissolution, or other sale or transfer of some or all of Our assets,
      whether as a going concern or as part of bankruptcy, liquidation, or
      similar proceeding, in which Personal Data held by Us about our Service
      users is among the assets transferred.
    </p>

    <p>
      <strong>For other purposes</strong>: We may use Your information for other
      purposes, such as data analysis, identifying usage trends, determining the
      effectiveness of our promotional campaigns and to evaluate and improve our
      Service, products, services, marketing and your experience.
    </p> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    let type = this.$route.params.type;
    if (type === "app") {
      this.$store.commit("setIsApp", true);
    } else {
      this.$store.commit("setIsApp", false);
    }
  },
  mounted() { },
  components: {},
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import "@/css/less_custom.less";

.container {
  width: 70%;
  height: 100%;
  // background-color: rgba(248, 248, 248, 1);
  margin: 0 auto;
  margin-bottom: 100px;
  margin-top: 60px;

  p {
    text-align: left !important;
    margin-top: 7px;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  h2,
  h3 {
    margin-top: 10px;
    text-align: left;
  }
}

@media screen and (max-width: @MobileMaxWidth) {
  .container {
    width: 90%;
  }
}
</style>
